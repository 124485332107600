import React, { useState } from 'react';
import classNames from 'classnames';
import { SectionProps } from '../../utils/SectionProps';
import ButtonGroup from '../elements/ButtonGroup';
import Button from '../elements/Button';
import Image from '../elements/Image';
import Modal from '../elements/Modal';
import "../../assets/css/countdown.css"
var contractaddress =  {
  address_c: {fontSize : '15px', color: '#fdc113'},
};
const CountDown = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  ...props
}) => {

  const [videoModalActive, setVideomodalactive] = useState(false);

  const openModal = (e) => {
    e.preventDefault();
    setVideomodalactive(true);
  }

  const closeModal = (e) => {
    e.preventDefault();
    setVideomodalactive(false);
  }

  const outerClasses = classNames(
    'hero section center-content',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'hero-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  (function () {
    const second = 1000,
      minute = second * 60,
      hour = minute * 60,
      day = hour * 24;



    let time = "jully 30, 2021 15:12:00";
    let countDown = new Date(time).getTime();
    const eleDay = document.getElementById("days");
    const eleHour = document.getElementById("hours");
    const eleMinutes = document.getElementById("minutes");
    const eleSeconds = document.getElementById("seconds")

    if (!eleDay || !eleHour || !eleMinutes || !eleSeconds) {
      return;
    }

    let x = setInterval(function () {

      let now = new Date().getTime(),
        distance = countDown - now;

      eleDay.innerText = Math.floor(distance / (day));

      eleHour.innerText = Math.floor((distance % (day)) / (hour));

      eleMinutes.innerText = Math.floor((distance % (hour)) / (minute));

      eleSeconds.innerText = Math.floor((distance % (minute)) / second);

      //do something later when date is reached
      if (distance < 0) {
        const container = document.getElementById("container-sm");
        container.style.display = "none";
        clearInterval(x);
      }
      //seconds
    }, 0)
  }());


  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container-sm" id="container-sm">
        <div className="count-down">
          <div className="hero-content">
            <div class="cpn-head">
              <h2 class="title ttu">COUNTDOWN </h2>
              <div class="countdown" data-date="2021/06/30">
                <div class="countdown-item">
                  <span class="countdown-time countdown-time-first" id="days">00</span>
                  <span class="countdown-text">Days</span>
                </div>
                <div class="countdown-item">
                  <span class="countdown-time" id="hours">00</span>
                  <span class="countdown-text">Hours</span>
                </div>
                <div class="countdown-item">
                  <span class="countdown-time" id="minutes">00</span>
                  <span class="countdown-text">Min</span>
                </div><div class="countdown-item">
                  <span class="countdown-time countdown-time-last" id="seconds">00</span>
                  <span class="countdown-text">Sec</span>
                </div></div>
                <p>
                <h4>Private Sale Are Living</h4>Contract: 
                <span style={contractaddress.address_c}>0xf00598af0CCf2D43613414F36f40123337f56E71</span>
              </p>

            </div>
          </div>
        </div>
      </div>
    </section>
  );
}


export default CountDown;