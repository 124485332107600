import React from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

const FooterNav = ({
  className,
  ...props
}) => {

  const classes = classNames(
    'footer-nav',
    className
  );

  return (
    <nav
      {...props}
      className={classes}
    >
      <ul className="list-reset">       
        
        {/* <li><a href="https://wshibatoken.gitbook.io/wshiba/wshiba-tokenomics" target="_blank">Tokenomics</a></li> */}
					    <li><a href="https://ants-finance.gitbook.io/ants-finance/whitepaper/7-roadmap" target="_blank">Roadmap</a></li>
					    <li><a href="https://twitter.com/AntsFinance" target="_blank">Twitter</a></li>
					    <li><a href="https://t.me/antsfinancechannel" target="_blank">Channel</a></li>
					    <li><a href="https://t.me/antsfinanceofficial" target="_blank">Chat</a></li>
					    <li><a href="mailto:support@ants.finance" target="_blank">support@ants.finance</a></li>
      </ul>
    </nav>
  );
}

export default FooterNav;