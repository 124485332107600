import React from 'react';
import Header from '../components/layout/Header';
import Footer from '../components/layout/Footer';
import '../assets/css/layout.css'

const LayoutDefault = ({ children }) => (
  <div class="main">
    <Header navPosition="right" className="reveal-from-bottom" />
    <main className="site-content">
      {children}
    </main>
    <Footer />
  </div>
);

export default LayoutDefault;