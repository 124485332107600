import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
// import Logo from './partials/Logo';
import '../../assets/css/header.css'
import logo from '../../assets/images/ants_logo.png'

const Header = ({

}) => {

  const [isActive, setIsactive] = useState(false);
  const nav = useRef(null);
  const hamburger = useRef(null);

  const openMenu = () => {

  }

  const closeMenu = () => {
    document.body.classList.remove('off-nav-is-active');
    nav.current && (nav.current.style.maxHeight = null);
    setIsactive(false);
  }

  const keyPress = (e) => {
    isActive && e.keyCode === 27 && closeMenu();
  }

  const clickOutside = (e) => {
    if (!nav.current) return
    if (!isActive || nav.current.contains(e.target) || e.target === hamburger.current) return;
    closeMenu();
  }

  function showDiv(Div) {
    const x = document.getElementById(Div);
    if (!x) {
      return
    }
    if (document.body.clientWidth < 1000) {
      const toggleX = document.getElementById(`toggle-line-x`);
      const toogleLine=  document.getElementById(`toggle-line`);
      if (x.style.display === "none") {
        x.style.display = "flex";
        x.style.left = "0 !important;"
        x.style.transitionProperty = "all";
        x.style.transitionDuration = "0.4s";
        x.style.transitionTimingFunction = "ease";
        x.style.transitionDelay = "0s";

     
        toggleX.style.display = "block";
        toogleLine.style.display="none"
      } else {
        x.style.display = "none";
        toggleX.style.display = "none";
        toogleLine.style.display="block"
      }
    }

  }

  return (
    <header>
      <div class="header-main">
        <div class="header-nav">
          <div class="left-toggle"></div>
          <div class="header-nav-toggle">
            <a href="#" class="navbar-toggle" data-menu-toggle="header-menu">
              <div class="toggle-line" id="toggle-line" onClick={() => showDiv("header_navbar")} >
                <span></span>
              </div>
              <div class="toggle-line-x" id="toggle-line-x" onClick={() => showDiv("header_navbar")} >
              </div>
            </a>
          </div>

        </div>
        <div class="header-navbar" id="header_navbar">

          <nav class="header-menu" id="header-menu">
            <ul class="menu">
              <li>  <a href="/" class="logo-link">
                <img class="logo-dark" src={logo}/>
              </a></li>
              <li class="menu-item"><a class="menu-item-a" href="/">Home</a></li>
              <li class="menu-item"><a class="menu-item-a" href="https://ants-finance.gitbook.io/ants-finance/">Whitepaper</a></li>
              <li class="menu-item"><a class="menu-item-a" href="/airdrop" target="_blank"> Airdrop </a></li>
              <li class="menu-item"><a class="menu-item-a" href="https://t.me/antsfinanceofficial">Telegram</a></li>
              <li class="menu-item"><a class="menu-item-a" href="https://twitter.com/AntsFinance" target="_blank"> Twitter </a></li>
              <li class="menu-item"><a class="menu-item-a" href="https://ants-finance.gitbook.io/ants-finance/whitepaper/7-roadmap">Roadmap</a></li>
            </ul>
            <ul class="ul-btn-truswallet">
              <li><a href="https://link.trustwallet.com/open_url?coin_id=56&url=https://www.ants.finance" class="btn-truswallet" ><span>Open in Trust Wallet</span></a></li>
            </ul>
          </nav>
          <div class="header-navbar-overlay" id="header-navbar-overlay" onClick={() => showDiv("header_navbar")}></div>
        </div>

      </div>


    </header>

  );
}

export default Header;
