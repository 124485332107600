import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { SectionProps } from '../../utils/SectionProps';
import Web3 from 'web3'
// import ButtonGroup from '../elements/ButtonGroup';
// import Button from '../elements/Button';
// import Image from '../elements/Image';
// import Modal from '../elements/Modal';

import '../../assets/css/purchase.css';
import '../../assets/css/style.css'

import { Form, Button } from 'react-bootstrap';
import { notification } from 'antd';
const propTypes = {
  ...SectionProps.types
}

const defaultProps = {
  ...SectionProps.defaults
}

const Purchase = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  ...props
}) => {
  const outerClasses = classNames(
    'purchase section center-content',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'purchase-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );
  const [symbol, setSymbol] = useState('');
  const [amount, setAmount] = useState(0.01);
  const [price, setPrice] = useState(0);
  const [balance, setBalance] = useState(0);
  const [claimAmount, setClaimAmount] = useState(0);
  const [claimCost, setClaimCost] = useState(0);
  const [f1Percent, setF1Percent] = useState(0);
  const [f2Percent, setF2Percent] = useState(0);
  const [f3Percent, setF3Percent] = useState(0);
  const onAmountChange = (event) => {
    setAmount(event.target.value)
  }

  const onPurchase = async () => {
    //console.log(typeof amount);
    if (!amount || isNaN(amount) === true) {
      notification.error({ message: "Amount must be number" })
      return
    }
    if (!props.account) {
      return;
    }

    // const BN = Web3.utils.BN
    const { token } = props
    const savedReferral = localStorage.getItem('referral')
    const referral = savedReferral ? savedReferral : props.account
    // const priceBN = new BN(price.toString())
    const bnbWeiBN = Web3.utils.toWei(amount.toString())
    // const bnbWeiBN = new BN(priceBN.mul(tokenBN))
    ////console.log('xxx BNB', Web3.utils.fromWei(bnbWeiBN.toString()))
    const saleAmount = await props.token.methods.saleAmount().call();
    //console.log('xxx saleAmount', Web3.utils.fromWei(saleAmount.toString()))
    await token.methods.buy(referral).send({ from: props.account, value: bnbWeiBN.toString() })
  }

  const onClaim = async () => {
    ////console.log('claim')
    if (!props.account) {
      return;
    }

    const { token } = props
    const savedReferral = localStorage.getItem('referral')
    const referral = savedReferral ? savedReferral : props.account
    const claimCost = await token.methods.claimCost().call()
    //console.log('xxx claimCost', Web3.utils.fromWei(claimCost.toString()))
    await token.methods.claim(referral).send({ from: props.account, value: claimCost.toString() })
  }

  const onCopy = () => {
    //console.log('copy')
    const el = document.createElement('textarea');
    el.value = getRefUrl();
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
  }

  const getClaimAmount = async () => {
    const { token } = props
    if (!token) return
    const amount = await token.methods.claimAmount().call();
    //console.log('claim amoung ', amount);
    setClaimAmount(Web3.utils.fromWei(amount.toString()))

  }

  const getRefUrl = () => {
    const baseUrl = window.location.host + '/'
    return props.account ? baseUrl + props.account : 'not found'
  }

  const loadSymbol = async () => {
    if (!props.token) return
    const symbol = await props.token.methods.symbol().call()
    //console.log('token symbol', symbol)
    setSymbol(symbol)
  }

  const loadPrice = async () => {
    if (!props.token) return
    const price = await props.token.methods.price().call()
    //console.log('token price', price.toString())
    setPrice(price.toString())
  }

  const loadBalance = async () => {
    if (!props.token || !props.account) return
    const balance = await props.token.methods.balanceOf(props.account).call()
    //console.log('token balance', balance.toString())
    setBalance(balance.toString())
  }

  const getTokenByBNB = (bnb) => {
    const token = (bnb / Number(Web3.utils.fromWei(price.toString())))
    return formatNum(token)
  }

  const loadRefClaimAmount = async () => {
    if (!props.token) return
    const claimCost = await props.token.methods.refClaimAmount().call();
    //console.log('claimCost: ', Web3.utils.fromWei(claimCost.toString()));
    setClaimCost(Web3.utils.fromWei(claimCost.toString()))

  }

  const loadF1Persent = async () => {
    if (!props.token) return
    const f1Percent = await props.token.methods.f1Percent().call();
    //console.log('f1Percent: ', f1Percent.toString());
    setF1Percent(f1Percent.toString())

  }

  const loadF2Persent = async () => {
    if (!props.token) return
    const f2Percent = await props.token.methods.f2Percent().call();
    setF2Percent(f2Percent.toString())

  }

  const loadF3Persent = async () => {
    if (!props.token) return
    const f3Percent = await props.token.methods.f3Percent().call();
    setF3Percent(f3Percent.toString())

  }


  useEffect(() => {
    const referral = props.match.params.referral
    const isAddress = Web3.utils.isAddress(referral)
    if (isAddress && referral != localStorage.getItem('referral')) {
      //console.log('updating referral...')
      localStorage.setItem('referral', referral)
    }

    //console.log('current referral', localStorage.getItem('referral'))
    loadSymbol()
    loadPrice()
    loadBalance()
    getClaimAmount()
    loadRefClaimAmount()
    loadF1Persent()
    loadF2Persent()
    loadF3Persent()
  });

  const fromWei = (_number) => {
    const num = Web3.utils.fromWei(_number.toString())
    return formatNum(num)
  }

  const formatNum = (num) => {
    return Number(Number(num).toFixed(0)).toLocaleString("en-AU");
  }
  var styles = {
    box: { backgroundColor: 'yellow', height: '100px', width: '200px' },
    boxA: { color: 'red' },
  };

  return (

    // 
    <section
      {...props}
    //className={outerClasses}
    >
      {/*  */}

      {/* <div class="container-purchase">
        <img src="https://cdoge-net.vercel.app/img/services/3.svg" />
        <div class="container">
          <div class="section-heading text-center">

            <div class="dream-dots justify-content-center wow fadeInUp" data-wow-delay="0.2s" >
              <video controls autoPlay loop muted>
                <source src="https://cdn-tau.vercel.app/ants.mp4" type="video/mp4"></source>
              </video>
            </div>
            <h2 style={styles.box, styles.boxA} class="wow fadeInUp" data-wow-delay="0.3s" >Why HODL wSHIBA</h2>
          </div>
        </div>
      </div> */}

      <div class="container-purchase">
        <div class="div-purchase">
          <Form>
            <Form.Text>
              <div class="title">
                <h4 className="pupple-text"> PUBLIC SALE </h4>
                {/* <p className="pupple-text"> PancakeSwap Listing Rate: {getTokenByBNB(1)} {symbol} per BNB </p> */}
                <p className="pupple-text"> Your Balance: {fromWei(balance)} {symbol} </p>
              </div>

            </Form.Text>
            <Form.Group class="form-group">
              <div>
                <span>Enter BNB</span>
                <Form.Control onChange={(event) => onAmountChange(event)} type="input" lang="en-AU" defaultValue={amount.toLocaleString("en-AU")} class="input-number" />

              </div>
              <div>
                <Button variant="primary" onClick={() => onPurchase()} class="button-action">
                  Purchase
                </Button>
              </div>

            </Form.Group>
            <Form.Group class="form-group">
              <div class="claim">
                <Button variant="primary" onClick={() => onClaim()} class="button-claim" >
                  Claim {formatNum(claimAmount)} {symbol}
                </Button>
              </div>
            </Form.Group>
            <div className="hint-text">
              {/* <p>(*) Price: {Web3.utils.fromWei(price.toString())} BNB = 1 {symbol}</p> */}
              <p>(*) Price: 1BNB = {getTokenByBNB(1)} {symbol}</p>
              <p>(*) Minium purchase 0.01 BNB - Max purchase 5 BNB</p>
              {/* <p>(*) Rating Sale: 0.01 BNB = {getTokenByBNB(0.01)} {symbol}</p>
              <p>(*) Rating Sale: 0.1 BNB = {getTokenByBNB(0.1)}  {symbol}</p>
              <p>(*) Rating Sale: 1 BNB = {getTokenByBNB(1)}  {symbol}</p>
              <p>(*) Rating Sale: 5 BNB = {getTokenByBNB(5)}  {symbol}</p> 
              <p>(*) Your friends who buy through your link receive {f1Percent}% commission.</p>*/}
              <p>(*) Let’s share ANTF to get commission: level 1 - get {f1Percent}%; level 2 - {f2Percent}%; level 3 - {f3Percent}%. Not limit commission for you to get. Liquid immediately on PancakeSwap</p>
              <p>(*) Adding logo on Trust Wallet when having enough 2500 holders.</p>
            </div>
          </Form>
        </div>
        <div class="div-referal">
          <Form>
            <Form.Text>
              <div class="title">
                <h4 className="pupple-text"> Get your Referral link </h4>
              </div>
            </Form.Text>
            <Form.Group>
              <Form.Control type="text" readOnly={true} value={getRefUrl()} />
              <Button variant="primary" onClick={() => onCopy()}>
                Copy
              </Button>
            </Form.Group>
            <div className="hint-text">
              <p>(*) Each Referral friend claims {formatNum(claimCost)} {symbol}.</p>
              {/* <p>(*) Refer to Get {(Number(claimCost) / Number(claimAmount)) * 100}% all of Claim & {f1Percent}% of Purchase.</p> */}
              <p>(*) Let’s share to get {(Number(claimCost) / Number(claimAmount)) * 100}% all of Claim & {f1Percent}% of Purchase at Level 1; {f2Percent}% of Purchase at Level 2; {f3Percent}% of Purchase at Level 3.</p>
              <p>(*) There is no limit bonus for Referral.</p>
              {/* <p>(*) Added logo on Trust wallet, Pancakeswap.</p> */}
            </div>
          </Form>
        </div>


      </div>

      <div class="container-purchase">
        <section class="our_services_area section-padding-100-0 clearfix" id="services">
          <div class="container">
            <div class="row">
              <div class="col-12 col-sm-6 col-lg-6">
                <div class="service_single_content text-center mb-100 wow fadeInUp" data-wow-delay="0.2s">
                  <div class="service_icon">
                    <center><img alt="" src="https://cdoge-net.vercel.app/img/services/1.svg"></img></center>
                  </div>
                  <h6>Community Driven</h6>
                  <p>Making money is good, but making money together is even better. We choose to share our
                    success with the world to enhance the living standards of communities, your families, and
                    your bank accounts.</p>
                </div>
              </div>
              <div class="col-12 col-sm-6 col-lg-6">
                <div class="service_single_content text-center mb-100 wow fadeInUp" data-wow-delay="0.2s">
                  <div class="service_icon">
                  <center><img alt="" src="https://cdoge-net.vercel.app/img/services/3.svg"></img></center>
                  </div>
                  <h6>Community Driven</h6>
                  <p>Wdogecoin and wShiba will increase transparency in transactions by providing detailed and real-time logs of all transactions. We will be as transparent as possible while remaining competitive</p>
                </div>
              </div>
              <div class="col-12 col-sm-6 col-lg-6">
                <div class="service_single_content text-center mb-100 wow fadeInUp" data-wow-delay="0.2s">
                  <div class="service_icon">
                  <center><img alt="" src="https://cdoge-net.vercel.app/img/services/1.svg"></img></center>
                  </div>
                  <h6>Community Driven</h6>
                  <p>Making money is good, but making money together is even better. We choose to share our
                    success with the world to enhance the living standards of communities, your families, and
                    your bank accounts.</p>
                </div>
              </div>
              <div class="col-12 col-sm-6 col-lg-6">
                <div class="service_single_content text-center mb-100 wow fadeInUp" data-wow-delay="0.2s">
                  <div class="service_icon">
                  <center><img alt="" src="https://cdoge-net.vercel.app/img/services/3.svg"></img></center>
                  </div>
                  <h6>Community Driven</h6>
                  <p>Wdogecoin and wShiba will increase transparency in transactions by providing detailed and real-time logs of all transactions. We will be as transparent as possible while remaining competitive</p>
                </div>
              </div>
              <div class="col-12">
                <div class="service_single_content text-center mb-100 wow fadeInUp" data-wow-delay="0.2s">
                  <div class="service_icon">
                  <center><img alt="" src="https://cdoge-net.vercel.app/img/services/3.svg"></img></center>
                  </div>
                  <h6>Community Driven</h6>
                  <p>Wdogecoin and wShiba will increase transparency in transactions by providing detailed and real-time logs of all transactions. We will be as transparent as possible while remaining competitive</p>
                </div>
              </div>
            </div>
          </div>

        </section>
      </div>
      {/*  */}
      <div class="container-purchase" style={{paddingTop:"0px"}}>
        <section>
          <div class="section-heading text-center">
            {/* <div class="dream-dots justify-content-center wow fadeInUp" >
              <span></span><span></span><span></span><span></span><span></span><span></span><span></span>
            </div> */}
            <h2 class="wow fadeInUp">Roadmap</h2>
            {/* <p class="wow fadeInUp">The road come to the moon
              should be planned carefully</p> */}
          </div>

          <div class="container">
            <div class="row">
              <div class="timeline-split">
                  <div class="col-lg-12 col-md-12 col-sm-12">
                  <div class="timeline section-box-margin">
                  <div class="block block-left">
                             {/*<h3>Platform Development Starts</h3>
                            <span class="date">May 29, 2020</span> <span class="between">to</span> <span class="date">Jul 19, 2021</span> */}
                            <span class="date">🚩Q4, 2020 - Q1, 2021</span> 
                            <ul>
                                <li><span>Idea</span></li>
                                <li><span>Team building</span></li>
                                <li><span>Market research</span></li>
                                <li><span>Testing contract code</span></li>
                                <li><span>Website development</span></li>
                                <li><span>Whitepaper v0.1.1</span></li>
                            </ul>
                    </div>
                    <div class="block block-right mt-30">
                            {/* <h3>APP and CEX</h3>
                            <span class="date">Jul 20, 2021</span> <span class="between">to</span> <span class="date">Sep 23, 2021</span> */}
                            <span class="date">🚩Q2, 2021</span> 
                            <ul>
                                <li><span>DComplete website, contract code & tokenomics</span></li>
                                <li><span>Whitepaper v0.1.6</span></li>
                                <li><span>Launching Private Sale & Public Sale</span></li>
                                <li><span>Listing Pancakeswap</span></li>
                                <li><span>Listing CoinGecko</span></li>
                                <li><span>Listing on centralized exchange </span></li>
                            </ul>
                        </div>
                        <div class="block block-left mt-30">
                            {/* <h3>Crypto Wallet Release</h3>
                            <span class="date">Oct 08, 2021</span> <span class="between">to</span> <span class="date">Nov 16, 2021</span> */}
                             <span class="date">🚩Q3, 2021</span> 
                            <ul>
                                <li><span>Listing ANTF on Coinmarketcap</span></li>
                                <li><span>Listing ANTF on Coinpaprika</span></li>
                                <li><span>Upgrade Website</span></li>
                                <li><span>Release AntSwap</span></li>
                                <li><span>Building Community</span></li>
                                <li><span>Listing ANTF on another centralized exchange</span></li>
                            </ul>
                        </div>
                        <div class="block block-right mt-30">
                            {/* <h3>Release Bank &amp; Cards Phase</h3>
                            <span class="date">Dec 28, 2018</span> <span class="between">to</span> <span class="date">2022</span> */}
                            <span class="date">🚩Q4, 2021</span> 
                            <ul>
                                <li><span>Marketing Campaign</span></li>
                                <li><span>“Ant Nest” Sale Event (November 2021, only available one-time)</span></li>
                                <li><span>Listing ANTF on another centralized exchange</span></li>
                                <li><span>ANTF Development Report 2021</span></li>
                            </ul>
                        </div>
                        <div class="block block-left mt-30">
                            {/* <h3>Crypto Wallet Release</h3>
                            <span class="date">Oct 08, 2021</span> <span class="between">to</span> <span class="date">Nov 16, 2021</span> */}
                             <span class="date">🚩Q1, 2022</span> 
                            <ul>
                                <li><span>Release NFT marketplace</span></li>
                                <li><span>Release NFT creative</span></li>
                                <li><span>Expand ANTF ecosystem: Community expansion, market, partners, more exchange listing, new NFT features, DeFi…</span></li>
                            </ul>
                        </div>
                        <div class="circle"></div>
                    </div>
                  </div>
              </div>
            </div>
          </div>
        </section>
      </div>


      {/*  */}
    </section>
  );
}

Purchase.propTypes = propTypes;
Purchase.defaultProps = defaultProps;

export default Purchase;